.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    overflow: hidden;
}
    
.row {
  text-align: center;
}

.footerPosition {
  padding-left: 20px;
}

