@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  position: relative;
  min-height: 100%;
}





.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

.aboutuscontent {
    color: black;
    font-family: 'Miriam Libre', sans-serif;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 20px;
}

.AboutUs {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.card {
    font-family: 'Miriam Libre', sans-serif;
}

.list {
    font-size: 1.5rem;
    margin-left: 20px;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 50px;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.team {
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-top: 20px;
}

.spacing {
    margin: 50px;
}

.video_position {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 30px;
    padding-bottom: 60px;
    justify-content: center;
}

@media(min-width: 1024px) {

    .videodimensions {
        width: 1000px;
        height: 500px;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {

    .videodimensions {
        width: 600px;
        height: 300px;
    }
}

@media (max-width: 768px) and (min-width: 430px) {

    .videodimensions {
        width: 450px;
        height: 225px;
    }
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    overflow: hidden;
}
    
.row {
  text-align: center;
}

.footerPosition {
  padding-left: 20px;
}


.History {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    width: 100;
    padding-bottom:60px;
    min-height: 100vh;
}

.headerHistory {
    color: black;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 70px;
    font-family: 'Miriam Libre', sans-serif;
}

.img_position1 {
    width: 200px;
    float: left;
    margin: 10px; 
}

.img_position2 {
    width: 300px;
    float: right;
    margin: 10px;
}

.founderMessage {
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Miriam Libre', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

span {
    color: white;
    font-weight: 700;
    font-family: 'Miriam Libre', sans-serif;
}
.Intro {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    padding-bottom: 75px;
    min-height: 100vh;
}

.heading {
    color: white;
    font-weight: 500!important;
    margin-bottom: 20px;
    margin-top: -120px;
    font-family: 'Miriam Libre', sans-serif;
}

.to_do1 {
    color: white;
    font-style: italic;
    font-family: 'Miriam Libre', sans-serif;
    font-weight: normal;
    font-size: large;
    margin-bottom: 10px; 
    display: none;  
}    

.to_do2 {
    color: white;
    font-style: italic;
    font-family: 'Miriam Libre', sans-serif;
    font-weight: normal;
    font-size: small;
    margin-bottom: 10px;
    display: none;
}    

@media(min-width: 768px) {
    .to_do1 {
        display: block;
    }
}

@media(max-width: 767px) {
    .to_do2 {
        display: block;
    }
}

.imageIndent {
    display: flex;
    flex-flow: row wrap;
}

@media(min-width: 1024px) {

    .titleImage {
        max-width: 50%;
        margin-top: -50px;
    }

    .footer {
        font-size: 1rem;
    }
}

@media (max-width: 1024px) and (min-width: 600px) {

    .titleImage {
        max-width: 75%;
        margin-top: -50px;
    }

    .display-4 {
        font-size: 2.5rem!important;
    }

    .footer {
        font-size: 0.75rem;
    }
}

@media (max-width: 600px) {

    .titleImage {
        max-width: 75%;
        margin-bottom: -40px;
    }

    .display-4 {
        font-size: 1.5rem!important;
    }

    .footer {
        font-size: 0.5rem;
    }

    .heading {
        margin-top: 10px;
    }
}


@media(max-width: 1500px) and (min-width: 1100px) {
    .home {
        font-size: 2.5rem;
        padding-top: 300px;
        padding-left: 600px
    }
    .name {
        font-size: 3.5rem;
        padding-left: 500px
    }
  }
  

.Gallery {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: black;
    width: 100;
    padding-bottom:60px;
    min-height: 100vh;
}

.headerGallery {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 70px;
    padding-top: 100px;
}

a.button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: black;
}

.button_position {
    margin-bottom: 20px;
    text-align: center;
}

.btn {
    margin-left: 10vw;
    margin-right: 10vw;
}

@media (max-width: 600px) {

    .button_position {
        margin-bottom: 2rem;
        text-align: center;
    }
    
    .btn {
        margin-left: 1vw;
        margin-right: 1vw;
    }
}

.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

/* .videocontent {
    color: black;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 20px;
} */

.Video {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.video1 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
}

.video2 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 10px;
    padding-bottom: 100px;
    justify-content: center;
}

@media(min-width: 1024px) {

    .videodimensions {
        width: 800px;
        height: 400px;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {

    .videodimensions {
        width: 600px;
        height: 300px;
    }
}

@media (max-width: 768px) and (min-width: 430px) {

    .videodimensions {
        width: 450px;
        height: 225px;
    }
}

.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

.Workshop {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.v1 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 30px;
    padding-bottom: 75px;
    justify-content: center;
}
.Year {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: black;
    width: 100;
    min-height: 100vh;
}

.headerYear {
    color: black;
    padding-top: 70px;
    text-align: center;
    margin-bottom: 50px;
}

.spacing {
    margin: 50px;
}

.positionCardtext {
    margin-top: 40px;
}

.card {
    font-family: 'Miriam Libre', sans-serif;
}
