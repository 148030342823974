@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

/* .videocontent {
    color: black;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 20px;
} */

.Video {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.video1 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 10px;
    padding-bottom: 20px;
    justify-content: center;
}

.video2 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 10px;
    padding-bottom: 100px;
    justify-content: center;
}

@media(min-width: 1024px) {

    .videodimensions {
        width: 800px;
        height: 400px;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {

    .videodimensions {
        width: 600px;
        height: 300px;
    }
}

@media (max-width: 768px) and (min-width: 430px) {

    .videodimensions {
        width: 450px;
        height: 225px;
    }
}
