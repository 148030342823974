@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.History {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    width: 100;
    padding-bottom:60px;
    min-height: 100vh;
}

.headerHistory {
    color: black;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 70px;
    font-family: 'Miriam Libre', sans-serif;
}

.img_position1 {
    width: 200px;
    float: left;
    margin: 10px; 
}

.img_position2 {
    width: 300px;
    float: right;
    margin: 10px;
}

.founderMessage {
    margin-left: 20px;
    margin-right: 20px;
    font-family: 'Miriam Libre', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

span {
    color: white;
    font-weight: 700;
    font-family: 'Miriam Libre', sans-serif;
}