@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.Gallery {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: black;
    width: 100;
    padding-bottom:60px;
    min-height: 100vh;
}

.headerGallery {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 70px;
    padding-top: 100px;
}

a.button {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: black;
}

.button_position {
    margin-bottom: 20px;
    text-align: center;
}

.btn {
    margin-left: 10vw;
    margin-right: 10vw;
}

@media (max-width: 600px) {

    .button_position {
        margin-bottom: 2rem;
        text-align: center;
    }
    
    .btn {
        margin-left: 1vw;
        margin-right: 1vw;
    }
}
