@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.Intro {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    padding-bottom: 75px;
    min-height: 100vh;
}

.heading {
    color: white;
    font-weight: 500!important;
    margin-bottom: 20px;
    margin-top: -120px;
    font-family: 'Miriam Libre', sans-serif;
}

.to_do1 {
    color: white;
    font-style: italic;
    font-family: 'Miriam Libre', sans-serif;
    font-weight: normal;
    font-size: large;
    margin-bottom: 10px; 
    display: none;  
}    

.to_do2 {
    color: white;
    font-style: italic;
    font-family: 'Miriam Libre', sans-serif;
    font-weight: normal;
    font-size: small;
    margin-bottom: 10px;
    display: none;
}    

@media(min-width: 768px) {
    .to_do1 {
        display: block;
    }
}

@media(max-width: 767px) {
    .to_do2 {
        display: block;
    }
}

.imageIndent {
    display: flex;
    flex-flow: row wrap;
}

@media(min-width: 1024px) {

    .titleImage {
        max-width: 50%;
        margin-top: -50px;
    }

    .footer {
        font-size: 1rem;
    }
}

@media (max-width: 1024px) and (min-width: 600px) {

    .titleImage {
        max-width: 75%;
        margin-top: -50px;
    }

    .display-4 {
        font-size: 2.5rem!important;
    }

    .footer {
        font-size: 0.75rem;
    }
}

@media (max-width: 600px) {

    .titleImage {
        max-width: 75%;
        margin-bottom: -40px;
    }

    .display-4 {
        font-size: 1.5rem!important;
    }

    .footer {
        font-size: 0.5rem;
    }

    .heading {
        margin-top: 10px;
    }
}

