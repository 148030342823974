@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

.aboutuscontent {
    color: black;
    font-family: 'Miriam Libre', sans-serif;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 20px;
}

.AboutUs {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.card {
    font-family: 'Miriam Libre', sans-serif;
}

.list {
    font-size: 1.5rem;
    margin-left: 20px;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 50px;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.team {
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-top: 20px;
}

.spacing {
    margin: 50px;
}

.video_position {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 30px;
    padding-bottom: 60px;
    justify-content: center;
}

@media(min-width: 1024px) {

    .videodimensions {
        width: 1000px;
        height: 500px;
    }
}

@media (max-width: 1024px) and (min-width: 769px) {

    .videodimensions {
        width: 600px;
        height: 300px;
    }
}

@media (max-width: 768px) and (min-width: 430px) {

    .videodimensions {
        width: 450px;
        height: 225px;
    }
}
