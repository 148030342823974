@media(max-width: 1500px) and (min-width: 1100px) {
    .home {
        font-size: 2.5rem;
        padding-top: 300px;
        padding-left: 600px
    }
    .name {
        font-size: 3.5rem;
        padding-left: 500px
    }
  }
  
