@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.header {
    color: black;   
    font-family: 'Miriam Libre', sans-serif;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 70px;
}

.Workshop {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: white;
    min-height: 100vh;
}

.message {
    text-align: center;
    font-family: 'Miriam Libre', sans-serif;
    margin-bottom: 40px;
}

.v1 {
    display:flex;
    position:relative;
    margin:0; 
    padding-top: 30px;
    padding-bottom: 75px;
    justify-content: center;
}