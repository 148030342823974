@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre&display=swap');

.Year {
    background: radial-gradient(circle, rgba(255,198,165,1) 0%, rgba(242,136,75,1) 100%);
    color: black;
    width: 100;
    min-height: 100vh;
}

.headerYear {
    color: black;
    padding-top: 70px;
    text-align: center;
    margin-bottom: 50px;
}

.spacing {
    margin: 50px;
}

.positionCardtext {
    margin-top: 40px;
}

.card {
    font-family: 'Miriam Libre', sans-serif;
}